<template>
  <div class="flex items-center justify-between py-1">
    <NuxtLink
      :to="localePath(`/professors/short-challenge/${props.shortCompetition.id}`)"
      class="focus:outline-none ring-0"
      @click="handleLinkClicked"
    >
      <div class="flex items-center gap-2">
        <img
          class="h-12"
          :src="props.shortCompetition.iconUrl"
          :alt="`logo of ${props.shortCompetition.name}`"
        />
        <p class="text-neutral_50 font-bold text-[14px] leading-[150%]">
          {{ props.shortCompetition.name }}
        </p>
      </div>
    </NuxtLink>
    <ButtonComponent
      type="text"
      text-size="text-[12px]"
      font-thickness="font-normal"
      :text="$t('play')"
      @click="
        router.push({
          path: localePath(`/professors/short-challenge/${props.shortCompetition.id}`)
        })
      "
    />
  </div>
</template>
<script lang="ts" setup>
import type { ShortCompetition } from '~/interfaces';
const router = useRouter();
const localePath = useLocalePath();

const emits = defineEmits(['link-clicked']);

const props = defineProps({
  shortCompetition: {
    type: Object as PropType<ShortCompetition>,
    required: true
  }
});
const handleLinkClicked = () => {
  emits('link-clicked', props.shortCompetition.id);
};
</script>
