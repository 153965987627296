<template>
  <div
    class="flex h-[64px] bg-neutral_950 w-full fixed top-0 items-center justify-center px-4 pt-4 pb-2"
  >
    <div class="flex items-center justify-between w-full max-w-[360px]">
      <div class="flex items-center gap-1">
        <ClientOnly>
          <ion-icon
            name="menu-outline"
            class="text-[24px] text-base_white cursor-pointer"
            @click="isSideBarOpen = true"
          />
        </ClientOnly>
        <NuxtLink :to="localePath('/')" @click="handleLogoClicked">
          <img src="../../assets/img/mobileNavLogo.svg" class="p-2" alt="logo picture" />
        </NuxtLink>
      </div>
      <div class="flex items-center gap-4 relative">
        <!-- predvidjeno za chat -->
        <!-- <ion-icon name="chatbubble-outline" class="text-[24px] color-base_white"/> -->
        <NuxtLink class="max-h-6" :to="localePath('/activities')">
          <ClientOnly>
            <ion-icon
              name="notifications-outline"
              class="text-[24px] text-base_white cursor-pointer"
            />
          </ClientOnly>
        </NuxtLink>

        <ClientOnly>
          <span
            v-if="newNotificationsNumber"
            class="absolute flex h-2 w-2 top-[3px] left-[16px] pointer-events-none"
          >
            <span
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary_200 opacity-75"
            />
            <span class="relative inline-flex rounded-full h-2 w-2 bg-primary_200" />
          </span>
        </ClientOnly>

        <NuxtLink class="max-h-6" :to="localePath('/users-search')">
          <ClientOnly>
            <ion-icon name="search" class="text-[24px] text-base_white cursor-pointer" />
          </ClientOnly>
        </NuxtLink>
        <NuxtLink :to="localePath(`/user-posts/${userId}/all`)">
          <UserPicture v-if="userStore.user" :user="userStore.user" :display-username="false" />
        </NuxtLink>
      </div>
    </div>
  </div>
  <MobileSideBar :is-open="isSideBarOpen" @close-bar="isSideBarOpen = false" />
</template>
<script lang="ts" setup>
import { getSocket } from '@/socket';
import { userRepository } from '~/repositories/userRepository';
import type { UserInfo } from '~/interfaces';
import { useUserStore } from '~/store/user';
const localePath = useLocalePath();

const userStore = useUserStore();
const { $api } = useNuxtApp();
const userRepo = userRepository($api);
const newNotificationsNumber = ref(0);
const { sendSignalToRealodPosts } = useGlobalFunctions();
const route = useRoute();
const currentUserInfo = ref<UserInfo | null>(null);
const userId = userStore.user?.id;
const isSideBarOpen = ref(false);

onMounted(async () => {
  if (userId) {
    currentUserInfo.value = await userRepo.getUserProfileInfo(userId as string);
    newNotificationsNumber.value = currentUserInfo.value.numberOfNotifications;
  }

  getSocket()?.on('new_notification', (message) => {
    const notification = JSON.parse(message);
    console.log('Received new_notification message:', notification);
    newNotificationsNumber.value += 1;
  });
});
const handleLogoClicked = () => {
  if (route.path == '/') sendSignalToRealodPosts();
};
</script>
