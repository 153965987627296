<template>
  <div
    class="w-full bg-neutral_950 flex items-center justify-center pl-3 pt-4 pb-2 pr-6 h-[64px] fixed top-0"
  >
    <div class="w-full flex max-w-[360px] items-center justify-between">
      <div class="flex cursor-pointer items-center gap-1" @click="sendRouterBackIfPossible">
        <ClientOnly>
          <ion-icon class="text-[24px] text-base_white" name="arrow-back-outline" />
        </ClientOnly>
        <p class="text-[14px] leading-[150%] tracking-[-0.28px] text-base_white">
          {{ textToDisplay }}
        </p>
      </div>
      <NuxtLink :to="localePath('/')">
        <!-- <ClientOnly>
          <ion-icon class="text-[24px] text-base_white" name="home-outline"/>
        </ClientOnly> -->
        <img class="h-10" src="../../assets/img/mobileAppHeaderBarLogo.png" alt="logo picture" />
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUserStore } from '~/store/user';

const localePath = useLocalePath();
const { sendRouterBackIfPossible } = useGlobalFunctions();
const route = useRoute();
const userStore = useUserStore();
const { t } = useI18n();
const textByRoute = [
  {
    route: 'bettingshopid',
    text: t('bettingShop')
  },
  {
    route: 'activities',
    text: t('activities')
  },
  {
    route: 'settings',
    text: t('myProfile')
  },
  {
    route: 'userpostsidtype',
    text: route.params.id === userStore.user?.id ? t('myPosts') : t('usersPosts')
  },
  {
    route: 'userrelation',
    text: route.params.relation === 'followers' ? t('followers3') : t('following')
  },
  {
    route: 'professorschallenge',
    text: 'Professors challenge'
  },
  {
    route: 'professorsshortchallenge',
    text: t('challenge2')
  },
  {
    route: 'professors',
    text: t('infoAndPromo')
  }
];

const textToDisplay = computed(() => {
  let text = t('back');

  const foundRoute = textByRoute.find((r) =>
    route.name?.toString().replaceAll('-', '').includes(r.route)
  );

  if (foundRoute) {
    text = foundRoute.text;
  }

  return text;
});
</script>
