<template>
  <div
    class="p-4 flex sm:flex-col gap-4 items-center sm:rounded-[8px] border border-primary_900 justify-center"
  >
    <div class="flex flex-col gap-2 sm:gap-4 items-center">
      <UserPicture
        :user="guest"
        :size="commonStore.isMobile ? 'sm' : 'lg'"
        :display-username="false"
      />
      <p class="text-[12px] font-medium sm:text-[22px] sm:font-bold leading-[120%] text-neutral_50">
        {{ $t('guest') }}
      </p>
    </div>
    <div
      class="sm:w-full sm:border-t border-primary_900 flex items-center gap-2 justify-center sm:pt-3"
    >
      <NuxtLink :to="localePath('/register')">
        <ButtonComponent type="text" :text="$t('registerIn')" />
      </NuxtLink>
      <NuxtLink :to="localePath('/login')">
        <ButtonComponent type="filled" :text="$t('login')" />
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { User } from '~/interfaces';
import { useCommonStore } from '~/store/commonStore';
const guest = {
  username: 'Guset'
} as User;
const commonStore = useCommonStore();
const localePath = useLocalePath();
</script>
