<template>
  <div
    v-show="showUserProfileCard"
    class="sm:px-4 py-2 sm:pt-8 sm:pb-4 flex sm:flex-col justify-center sm:justify-start items-center gap-4 w-full sm:rounded-2xl border-t border-b sm:border border-primary_900"
  >
    <div class="flex mr-1 sm:mr-0 flex-col gap-2 sm:gap-4 items-center">
      <UserPicture
        v-if="currentUserInfo"
        :size="commonStore.isMobile ? 'md' : 'lg'"
        :display-username="false"
        :user="currentUserInfo"
      />
      <p
        class="text-[12px] sm:text-[22px] font-medium sm:font-bold sm:leading-[120%] text-neutral_50"
      >
        {{ currentUserInfo?.username }}
      </p>
    </div>
    <!-- <div class="h-px hidden sm:block w-full bg-primary_900" /> -->
    <!-- <div class="w-px h-[70px] bg-primary_900 block sm:hidden"></div> -->
    <div class="flex py-[2px] items-center gap-3 sm:gap-4">
      <!-- <NuxtLink
        class="hidden sm:block"
        :to="localePath('/activities')"
        @click="newNotificationsNumber = 0"
      >
        <div class="relative">
          <p class="font-medium text-[12px] text-neutral_200 sm:text-neutral_50">
            {{ $t('notifications') }}
          </p>
          <p
            class="text-[14px] sm:text-[18px] font-bold leading-[130%] tracking-[-0.36px] text-primary_500"
          >
            {{ newNotificationsNumber }}
          </p>

          <span v-if="newNotificationsNumber" class="absolute flex h-2 w-2 top-[2px] right-[-12px]">
            <span
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary_200 opacity-75"
            />
            <span class="relative inline-flex rounded-full h-2 w-2 bg-primary_200" />
          </span>
        </div>
      </NuxtLink> -->
      <NuxtLink :to="localePath(`/user-posts/${userId}/all`)">
        <div>
          <p class="font-medium text-[12px] text-neutral_200 sm:text-neutral_50">
            {{ capitalize($t('posts')) }}
          </p>
          <p class="text-[14px] font-bold leading-[130%] tracking-[-0.36px] text-primary_500">
            {{ currentUserInfo?.postCount || 0 }}
          </p>
        </div>
      </NuxtLink>
      <div class="w-px hidden sm:block h-full bg-primary_800" />
      <NuxtLink :to="localePath('/user/following')">
        <div>
          <p class="font-medium text-[12px] text-neutral_200 sm:text-neutral_50">
            {{ $t('following') }}
          </p>
          <p class="text-[14px] font-bold leading-[130%] tracking-[-0.36px] text-primary_500">
            {{ currentUserInfo?.numberOfFollowing || 0 }}
          </p>
        </div>
      </NuxtLink>
      <div class="w-px hidden sm:block h-full bg-primary_800" />
      <NuxtLink :to="localePath('/user/followers')">
        <div>
          <p class="font-medium text-[12px] text-neutral_200 sm:text-neutral_50">
            {{ $t('followers2') }}
          </p>
          <p class="text-[14px] font-bold leading-[130%] tracking-[-0.36px] text-primary_500">
            {{ currentUserInfo?.numberOfFollowers || 0 }}
          </p>
        </div>
      </NuxtLink>
      <div class="w-px h-[36px] bg-primary_800" />
      <ClientOnly>
        <ion-icon
          name="settings-outline"
          class="text-[24px] text-base_white cursor-pointer"
          @click="router.push(localePath('/settings'))"
        />
      </ClientOnly>
    </div>
  </div>
  <!-- <div class="h-px hidden sm:block bg-primary_900 w-full" /> -->
</template>
<script setup lang="ts">
import { getSocket } from '@/socket';
import { useUserStore } from '~/store/user';
import { userRepository } from '~/repositories/userRepository';
import type { UserInfo } from '~/interfaces';
import UserPicture from './UserPicture.vue';
import { useCommonStore } from '~/store/commonStore';
const route = useRoute();

const userStore = useUserStore();
const commonStore = useCommonStore();
const { $api } = useNuxtApp();
const localePath = useLocalePath();
const userRepo = userRepository($api);
const userId = userStore.user?.id;
const { capitalize } = useTextFunctions();
const currentUserInfo = ref<UserInfo | null>(null);
const newNotificationsNumber = ref(0);
const router = useRouter();

onMounted(async () => {
  if (userId) {
    currentUserInfo.value = await userRepo.getUserProfileInfo(userId as string);
    newNotificationsNumber.value = currentUserInfo.value.numberOfNotifications;
  }

  getSocket()?.on('new_notification', (message) => {
    const notification = JSON.parse(message);
    console.log('Received new_notification message:', notification);
    newNotificationsNumber.value += 1;
  });
});
const showUserProfileCard = computed(() => {
  const routeName = route.name?.toString();
  if (routeName?.includes('settings')) {
    return false;
  } else if (routeName?.includes('user-posts-id') && route.params.id == userId) {
    return false;
  }
  return true;
});
</script>
