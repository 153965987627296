<template>
  <TransitionRoot as="template" :show="props.isOpen">
    <Dialog class="relative z-10" @close="emits('close-bar')">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-[296px]">
                <div class="flex h-full flex-col gap-2 overflow-y-scroll bg-neutral_500 p-8">
                  <div class="h-[40px] flex items-center">
                    <p
                      class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                    >
                      {{ $t('contact') }}
                    </p>
                  </div>
                  <a href="mailto:contact@underdogz.com">
                    <div class="flex h-12 items-center gap-2 px-2">
                      <ClientOnly>
                        <ion-icon class="text-[32px] text-base_white" name="mail-outline" />
                      </ClientOnly>
                      <p class="text-base_white font-medium leading-[150%]">Underdogz</p>
                    </div>
                  </a>
                  <div tabindex="2" class="h-px w-full outline-none bg-primary_800" />
                  <template v-if="userId">
                    <div class="h-[40px] flex items-center">
                      <p
                        class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                      >
                        {{ $t('myProfile') }}
                      </p>
                    </div>
                    <NuxtLink :to="localePath('/settings')">
                      <div class="flex h-12 items-center gap-2 px-2">
                        <ClientOnly>
                          <ion-icon class="text-[32px] text-base_white" name="settings-outline" />
                        </ClientOnly>
                        <p class="text-base_white font-medium leading-[150%]">
                          {{ $t('settings') }}
                        </p>
                      </div>
                    </NuxtLink>
                    <div
                      class="flex h-12 cursor-pointer items-center gap-2 px-2"
                      @click="handleLogout"
                    >
                      <ClientOnly>
                        <ion-icon class="text-[32px] text-base_white" name="log-out-outline" />
                      </ClientOnly>
                      <p class="text-base_white font-medium leading-[150%]">{{ $t('logout') }}</p>
                    </div>
                  </template>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useUserStore } from '~/store/user';

const userStore = useUserStore();
const userId = userStore.user?.id;
const localePath = useLocalePath();
const emits = defineEmits(['close-bar']);
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
});
const handleLogout = async () => {
  const { logout } = useLogin();
  await logout();
  navigateTo('/login');
};
</script>
