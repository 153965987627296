<template>
  <TransitionRoot as="template" :show="commonStore.isCompetitionFaqOpen">
    <Dialog class="relative z-10" @close="commonStore.setIsCompetitionFaqOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen bg-neutral_500 sm:max-w-[640px]">
                <div class="flex h-full flex-col overflow-y-scroll py-10 pl-8 pr-7">
                  <div class="w-full min-h-[40px] flex items-center justify-between">
                    <p
                      class="text-[12px] font-bold uppercase text-base_white tracking-[2.4px] leading-[150%]"
                    >
                      FAQ
                    </p>
                    <ClientOnly>
                      <ion-icon
                        class="text-base_white cursor-pointer"
                        name="close-outline"
                        @click="commonStore.setIsCompetitionFaqOpen(false)"
                      />
                    </ClientOnly>
                  </div>
                  <div class="my-2">
                    <img
                      class="h-[96px] sm:h-[84px]"
                      src="../../assets/img/logoProfesorsChallange.png"
                      alt="challenge logo"
                    />
                  </div>
                  <div class="flex flex-col gap-4 sm:gap-8">
                    <div v-for="i in 13" :key="i" class="flex flex-col gap-2">
                      <p
                        class="text-[18px] font-bold text-primary_500 sm:text-neutral_50 leading-[130%] tracking-[-0.36px]"
                      >
                        {{ $t(`COMPETITIONFAQ${i}.title`) }}
                      </p>
                      <p class="text-[14px] font-medium leading-[150%] tracking-[-0.28px]">
                        {{ $t(`COMPETITIONFAQ${i}.text`) }}
                      </p>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useCommonStore } from '~/store/commonStore';
const commonStore = useCommonStore();
</script>
