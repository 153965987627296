<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog class="relative z-10" @close="emits('close-dialog')">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-60 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-x-0 bottom-0 flex max-h-full pt-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-full">
                <div
                  class="flex relative h-full flex-col gap-2 bg-neutral_500 min-h-[480px] p-8 max-h-[480px] overflow-y-auto"
                >
                  <div class="h-[40px] mb-3 flex items-center justify-between">
                    <p
                      class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                    >
                      {{ $t('playNow') }}
                    </p>
                    <ClientOnly>
                      <ion-icon
                        class="text-base_white text-[16px] cursor-pointer"
                        name="close-outline"
                        @click="emits('close-dialog')"
                      />
                    </ClientOnly>
                  </div>
                  <ChallengePlayBar
                    v-for="challenge in props.shortCompetitions"
                    :key="challenge.id"
                    :short-competition="challenge"
                    @link-clicked="handleLinkClicked"
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import type { ShortCompetition } from '~/interfaces';

const emits = defineEmits(['close-dialog', 'link-changed']);

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  shortCompetitions: {
    type: Array as PropType<ShortCompetition[]>,
    required: true
  }
});
const handleLinkClicked = (id: number) => {
  emits('close-dialog');
  emits('link-changed', id);
};
</script>
