<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex px-4 py-4 items-start sm:p-6 z-20"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="toasterNotification.show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden toaster-shadow rounded-[8px] ring-1 ring-black ring-opacity-5"
          :class="stateClasses[toasterNotification.type]"
        >
          <div class="px-4 pt-4 pb-6 flex flex-col gap-2">
            <div class="flex items-center justify-between">
              <p class="text-[14px] font-bold leading-[150%] text-base_white">
                {{ toasterNotification.title }}
              </p>
              <div class="flex items-center gap-2">
                <div class="w-px h-[24px] opacity-30 bg-neutral_100"/>
                <ClientOnly>
                  <ion-icon
                    class="text-[24px] text-base_white cursor-pointer"
                    name="close-outline"
                    @click="closeNotification"
                  />
                </ClientOnly>
              </div>
            </div>
            <p class="text-[14px] leading-[150%] text-base_white tracking-[-0.28px] font-medium">
              {{ toasterNotification.text }}
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDialogStore } from '~/store/dialogStore';

const dialogStore = useDialogStore();

const toasterNotification = computed(() => {
  return {
    title: dialogStore.toasterNotification.notificationTitle,
    text: dialogStore.toasterNotification.notificationText,
    type: dialogStore.toasterNotification.notificationType,
    show: dialogStore.toasterNotification.showNotification
  };
});
const closeNotification = () => {
  dialogStore.closeToasterNotification();
};
const stateClasses = {
  error: 'bg-error_400',
  success: 'bg-primary_700',
  warning: 'bg-neutral_400'
};
</script>
<style scoped>
.toaster-shadow {
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.2),
    0px 6px 6px 0px rgba(0, 0, 0, 0.1);
}
</style>
