<template>
  <div
    class="px-6 pt-6 pb-3 flex flex-col gap-2 sm:rounded-[12px] border-y sm:border border-primary_900"
  >
    <div class="flex items-center gap-6 justify-between">
      <UserPicture v-if="user" :display-username="false" size="md" :user="user" />
      <!-- <div class="w-px h-[48px] bg-primary_900" /> -->
      <div class="flex items-center gap-4">
        <NuxtLink :to="localePath('/professors-challenge/placements')">
          <div class="flex flex-col items-center">
            <div class="flex items-center gap-2">
              <!-- <ClientOnly>
                <ion-icon class="text-primary_500" name="trending-up"/>
              </ClientOnly> -->
              <p class="text-[18px] font-medium leading-[130%] tracking-[-0.36px] text-base_white">
                {{ weeklyPlacement }}
              </p>
            </div>
            <p class="text-[12px] font-medium text-neutral_50">{{ $t('thisWeek') }}</p>
          </div>
        </NuxtLink>
        <NuxtLink :to="localePath('/professors-challenge/placements')">
          <div class="flex flex-col items-center">
            <div class="flex items-center gap-2">
              <!-- <ClientOnly>
                <ion-icon class="text-error_400" name="trending-down"/>
              </ClientOnly> -->
              <p class="text-[18px] font-medium leading-[130%] tracking-[-0.36px] text-base_white">
                {{ monthlyPlacement }}
              </p>
            </div>
            <p class="text-[12px] font-medium text-neutral_50">{{ $t('thisMonth') }}</p>
          </div>
        </NuxtLink>
        <div class="w-px h-[48px] bg-primary_900" />
        <ClientOnly>
          <ion-icon
            name="settings-outline"
            class="text-[24px] text-base_white cursor-pointer"
            @click="router.push(localePath('/settings'))"
          />
        </ClientOnly>
      </div>
    </div>
    <div class="h-px w-full bg-primary_800" />
    <div
      class="h-10 flex items-center justify-center gap-2 text-[12px] text-neutral_50 font-medium"
    >
      <p>{{ $t('youHavePlayed') }}</p>
      <p>
        {{ userStore.weekNumberOfPredictions }}/<span class="text-primary_500">10</span>
        {{ $t('currentWeek') }}
      </p>
      <p>
        {{ userStore.monthNumberOfPredictions }}/<span class="text-primary_500">45</span>
        {{ $t('currentMonth') }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { User } from '~/interfaces';
import { challengeRepository } from '~/repositories/challengeRepository';
import { userRepository } from '~/repositories/userRepository';
import { useUserStore } from '~/store/user';

const { $api } = useNuxtApp();
const userRepo = userRepository($api);
const challengeRepo = challengeRepository($api);
const userStore = useUserStore();
const userId = userStore.user?.id;
const router = useRouter();
const localePath = useLocalePath();
const user = ref<User | null>(null);
const weeklyPlacement = ref<string | number>(0);
const monthlyPlacement = ref<string | number>(0);
onMounted(async () => {
  user.value = (await userRepo.profile()).user;
  const response = await challengeRepo.getUsersChallengePositionsAndPredictionsNumber(
    userId as string
  );
  weeklyPlacement.value = response.weeklyPosition || '-';
  monthlyPlacement.value = response.monthlyPosition || '-';
  userStore.setMontNumberOfPredictions(response.monthlyPredictions);
  userStore.setWeekNumberOfPredictions(response.weeklyPredictions);
});
</script>
