<template>
  <div
    class="fixed bottom-0 left-0 w-full bg-neutral_500 h-[72px] flex items-center justify-center"
  >
    <div class="px-3 flex items-center justify-between max-w-[360px] w-full">
      <NuxtLink
        v-for="(item, index) in navItems"
        :key="index"
        :to="localePath(item.link)"
        @click="handleRouterClicked(item)"
      >
        <div class="flex min-w-[64px] max-w-[72px] flex-col items-center gap-1">
          <ClientOnly>
            <ion-icon
              v-if="item.iconType === 'icon'"
              class="text-primary_500 hover:rounded-[32px] py-[7px] px-[16px] hover:rounded-[32px] hover:bg-primary_700 border border-transparent"
              :class="[item.size]"
              :name="item.name"
            />
          </ClientOnly>
          <img
            v-if="item.iconType === 'image'"
            :src="item.image"
            :alt="item.text"
            class="h-[30px]"
          />
          <p class="text-primary_500 font-medium text-[12px]">
            {{ item.text }}
          </p>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { MenuItem } from '~/interfaces';
// import professorsChallengeLogo from '~/assets/img/profChallengeNavLogo.png';

const { t, locale } = useI18n();
const localePath = useLocalePath();
const route = useRoute();
const { sendSignalToRealodPosts } = useGlobalFunctions();

const handleRouterClicked = (item: MenuItem) => {
  if (item.link == '/' && route.path == '/') {
    sendSignalToRealodPosts();
  }
};

const initializeNavItems = () => {
  return [
    {
      name: 'home',
      size: 'text-[16px]',
      text: t('home'),
      link: '/',
      hasSeparator: false,
      iconType: 'icon'
    },
    {
      name: 'star',
      size: 'text-[16px]',
      text: t('myPage'),
      link: '/my-portal',
      hasSeparator: false,
      iconType: 'icon'
    },
    {
      name: 'logo-euro',
      size: 'text-[16px]',
      text: t('bettingShops'),
      link: '/betting-shops',
      hasSeparator: false,
      iconType: 'icon'
    },
    {
      name: 'school-outline',
      size: 'text-[16px]',
      text: t('challanges'),
      link: '/professors',
      hasSeparator: false,
      iconType: 'icon'
    }
    // {
    //   name: 'challenge-landing',
    //   size: 'text-[16px] py-[6px] px-[16px]',
    //   text: 'Professors',
    //   link: `/professors-challenge`,
    //   hasSeparator: false,
    //   iconType: 'image',
    //   image: professorsChallengeLogo
    // }
    // {
    //   name: 'search',
    //   size: 'text-[16px]',
    //   text: t('search'),
    //   link: `/users-search`,
    //   hasSeparator: true
    // }
  ] as MenuItem[];
};

const navItems = ref(initializeNavItems());

watch(locale, () => {
  navItems.value = initializeNavItems();
});
</script>
<style scoped>
.router-link-active ion-icon {
  border-radius: 32px;
  background-color: #354d14;
  color: #fff;
}
.router-link-active p {
  color: #fff;
}
</style>
