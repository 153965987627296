<template>
  <div class="flex flex-col gap-[10px] w-full max-w-[372px]">
    <div class="h-[40px] flex items-center mb-2">
      <p class="text-[12px] tracking-[2.4px] text-base_white uppercase font-bold leading-[150%]">
        {{ $t('trendingUsers') }}
      </p>
    </div>
    <div class="flex flex-col gap-4">
      <div v-for="(user, index) in trendingUsers" :key="index">
        <TrendingUser :user="user" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { postRepository } from '~/repositories/postRepository';

import type { User } from '~/interfaces';
const { $api } = useNuxtApp();
const postRepo = postRepository($api);

const { data: trendingUsers } = await useAsyncData<User[]>(
  'trendingUsers',
  () => postRepo.getTrendingUsers(),
  {
    server: true
  }
);
</script>
