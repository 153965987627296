<template>
  <div
    class="flex items-center justify-between py-1"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="flex items-center gap-3">
      <UserPicture
        gap="gap-3"
        size="sm"
        :color="isHovered ? 'text-primary_500' : 'text-neutral_50'"
        text-size="text-[16px]"
        :user="props.user"
      />
    </div>
    <div
      class="duration-[350ms] opacity-0 flex items-center justify-center"
      :class="isHovered ? 'opacity-100' : ''"
    >
      <ClientOnly>
        <ion-icon
          class="text-[19.5px] p-[2.25px] text-base_white cursor-pointer"
          name="footsteps"
        />
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserPicture from './UserPicture.vue';
import type { User } from '~/interfaces';

const props = defineProps({
  user: {
    type: Object as PropType<User>,
    required: true
  }
});

const isHovered = ref(false);
</script>
