<template>
  <div class="flex items-center flex-col gap-4 shrink-0">
    <div
      v-if="!props.showMenu"
      class="flex flex-col cursor-pointer items-center gap-1"
      @click="sendRouterBackIfPossible"
    >
      <ClientOnly>
        <div class="flex items-center justify-center w-12 h-[30px]">
          <ion-icon class="text-primary_500" name="arrow-back" />
        </div>
      </ClientOnly>
      <p class="text-[12px] font-medium text-primary_500">{{ $t('back') }}</p>
    </div>
    <div v-if="!props.showMenu" class="w-full h-px bg-primary_800" />
    <NuxtLink :to="localePath('/')" class="cursor-pointer" @click="handleLogoClick">
      <img src="/assets/img/logoNav.svg" alt="picture of logo" class="h-[72px]" />
    </NuxtLink>
    <ContextMenu v-if="props.showContextMenu" />
    <div
      v-for="(item, index) in navItems"
      v-if="props.showMenu"
      :key="index"
      class="flex w-full items-center flex-col gap-4"
    >
      <div
        v-if="item.hasSeparator"
        class="h-px w-full"
        :class="index ? 'bg-primary_900' : 'bg-primary_800'"
      />
      <div
        v-if="item.subMenu?.length"
        class="flex items-center flex-col cursor-pointer"
        @click="toogleSubMenu(item)"
      >
        <ClientOnly v-if="item.iconType === 'icon'">
          <ion-icon
            class="text-base_white hover:rounded-[32px] hover:rounded-[32px] hover:bg-primary_500 hover:text-neutral_950 border border-transparent"
            :class="[item.size]"
            :name="item.name"
          />
        </ClientOnly>
        <p class="text-base_white font-medium text-[13px]">
          {{ item.text }}
        </p>
      </div>
      <NuxtLink
        v-else
        :to="localePath(item.link as any)"
        class="flex items-center flex-col gap-[2px] cursor-pointer relative"
        @click="handleRouterClick(item)"
      >
        <ClientOnly>
          <ion-icon
            v-if="item.iconType === 'icon'"
            class="text-primary_500 hover:rounded-[32px] hover:bg-primary_500 hover:text-neutral_950 border border-transparent"
            :class="[item.size]"
            :name="item.name"
          />
        </ClientOnly>

        <img v-if="item.iconType === 'image'" :src="item.image" :alt="item.text" class="h-[54px]" />

        <p class="text-primary_500 font-medium text-[13px]">
          {{ item.text }}
        </p>
        <span
          v-if="item.link == '/activities' && newNotificationsNumber"
          class="absolute flex h-2 w-2 top-[5px] right-[24px]"
        >
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary_200 opacity-75"
          />
          <span class="relative inline-flex rounded-full h-2 w-2 bg-primary_200" />
        </span>
      </NuxtLink>
      <div v-if="item.subMenu?.length" v-show="item.showSubMenu">
        <div class="flex flex-col items-center gap-4">
          <NuxtLink
            v-for="subItem in item.subMenu"
            :key="subItem.link"
            :to="localePath(subItem.link as any)"
            class="flex items-center flex-col cursor-pointer"
          >
            <ClientOnly>
              <ion-icon
                class="text-base_white hover:rounded-[32px] hover:bg-primary_500 hover:text-neutral_950 border border-transparent"
                :class="[subItem.size, index == 0 ? 'bg-primary_700 rounded-[32px]' : '']"
                :name="subItem.name"
              />
            </ClientOnly>
            <p class="text-base_white font-medium text-[13px]">
              {{ subItem.text }}
            </p>
          </NuxtLink>
        </div>
      </div>
    </div>
    <div v-if="props.showMenu" class="h-px w-full bg-primary_900" />
    <NuxtLink v-if="props.showMenu" :to="localePath('/new-post')">
      <ButtonComponent :text="`${$t('newPost')}`" type="filled" />
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import { getSocket } from '@/socket';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { MenuItem, UserInfo } from '~/interfaces';
import { useUserStore } from '~/store/user';
import { userRepository } from '~/repositories/userRepository';
// import professorsChallengeLogo from '~/assets/img/profChallengeNavLogo.png';

const localePath = useLocalePath();
const { t, locale } = useI18n();
const userStore = useUserStore();
// const commonStore = useCommonStore();
// const router = useRouter();
const route = useRoute();
// const { debounce } = useGlobalFunctions();
const { sendRouterBackIfPossible } = useGlobalFunctions();
const { sendSignalToRealodPosts } = useGlobalFunctions();
const userId = userStore.user?.id;
const { $api } = useNuxtApp();
const userRepo = userRepository($api);
const currentUserInfo = ref<UserInfo | null>(null);
const newNotificationsNumber = ref(0);

const props = defineProps({
  showMenu: {
    type: Boolean,
    required: true
  },
  showContextMenu: {
    type: Boolean,
    default: true
  }
});

onMounted(async () => {
  if (userId) {
    currentUserInfo.value = await userRepo.getUserProfileInfo(userId as string);
    newNotificationsNumber.value = currentUserInfo.value.numberOfNotifications;
  }
  getSocket()?.on('new_notification', (message) => {
    const notification = JSON.parse(message);
    console.log('Received new_notification message:', notification);
    newNotificationsNumber.value += 1;
  });
});
const handleRouterClick = (item: MenuItem) => {
  if (item.link == '/activities') newNotificationsNumber.value = 0;
  if (item.link == '/' && route.path == '/') {
    sendSignalToRealodPosts();
  }
};
const handleLogoClick = () => {
  if (route.path == '/') sendSignalToRealodPosts();
};
const initializeNavItems = () => {
  return [
    {
      name: 'home',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('home'),
      link: '/',
      hasSeparator: true,
      iconType: 'icon'
    },
    {
      name: 'star-outline',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('myPage'),
      link: '/my-portal',
      hasSeparator: false,
      iconType: 'icon'
    },
    {
      name: 'logo-euro',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('bettingShops'),
      link: '/betting-shops',
      hasSeparator: false,
      iconType: 'icon'
    },
    // {
    //   name: 'person',
    //   size: 'text-[20px] py-[5px] px-[14px]',
    //   text: t('myProfile'),
    //   hasSeparator: true,
    //   subMenu: [
    //     {
    //       name: 'footsteps',
    //       size: 'text-[20px] py-[5px] px-[14px]',
    //       text: t('following'),
    //       link: '/users'
    //     },
    //     {
    //       name: 'information-circle-sharp',
    //       size: 'text-[20px] py-[5px] px-[14px]',
    //       text: t('activities'),
    //       link: '/activities'
    //     },
    //     {
    //       name: 'settings-sharp',
    //       size: 'text-[20px] py-[5px] px-[14px]',
    //       text: t('settings'),
    //       link: '/settings'
    //     }
    //   ],
    //   showSubMenu: false
    // },
    {
      name: 'notifications-outline',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('notifications'),
      link: `/activities`,
      hasSeparator: true,
      iconType: 'icon'
    },
    {
      name: 'search',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('search'),
      link: `/users-search`,
      hasSeparator: false,
      iconType: 'icon'
    },
    {
      name: 'mail-outline',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('contact'),
      //ovo ovako radi iako je nuxt link :)
      link: `mailto:contact@underdogz.com`,
      hasSeparator: false,
      iconType: 'icon'
    },
    {
      name: 'school-outline',
      size: 'text-[16px] py-[6px] px-[16px]',
      text: t('challanges'),
      link: '/professors',
      hasSeparator: false,
      iconType: 'icon'
    }
    // {
    //   name: 'challenge-landing',
    //   size: 'text-[16px] py-[6px] px-[16px]',
    //   text: 'Professors',
    //   link: `/professors-challenge`,
    //   hasSeparator: false,
    //   iconType: 'image',
    //   image: professorsChallengeLogo
    // }
  ] as MenuItem[];
};

const navItems = ref(initializeNavItems());

watch(locale, () => {
  navItems.value = initializeNavItems();
});

const toogleSubMenu = (item: any) => {
  if ('showSubMenu' in item) {
    item.showSubMenu = !item.showSubMenu;
  }
};
</script>

<style scoped>
.router-link-active ion-icon {
  border-radius: 32px;
  background-color: #3b541d;
  color: #fff;
}
.router-link-active p {
  color: #fff;
}
</style>
